// @flow
import React, { useState } from 'react'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import { Layout } from '../components/Layout/Layout'
import { SEO } from '../components/Layout/Seo'
import { getUser, isLoggedIn, setUser } from '../services/auth'
import TextLogo from '../svgs/text-logo.svg'

const Index = () => {
  const [username, setUsername] = useState<string>('Pique')
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState(false)

  // TODO: Replace this with proper user authentication that queries accelerator
  const organisations = useStaticQuery(graphql`
    query OrganisationQuery {
      allPiqueOrganisation {
        edges {
          node {
            name
            url_slug
          }
        }
      }
    }
  `).allPiqueOrganisation.edges.map(({ node }) => ({
    ...node,
    password: node.name.charAt(0).toLowerCase() + node.name.slice(1).replace(/\s/g, ''),
  }))

  if (isLoggedIn()) {
    navigate(getUser().home)
  }

  const handleSubmit = (event: SyntheticInputEvent<HTMLFormElement>) => {
    event.preventDefault()

    const user = organisations.find(({ name }) => name === username)

    if (user && user.password === password) {
      setUser({ username, home: user.url_slug })
      return true
    }

    return false
  }

  return (
    <Layout>
      <SEO title="Home" />
      <div className="container align-self-center">
        <div className="row">
          <div className="ml-auto mr-auto col-md-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <h1 className="sr-only">Pique Data</h1>
                <div className="text-center mb-2">
                  <TextLogo />
                </div>
                {error && (
                  <div className="alert alert-danger small" role="alert">
                    Username or Password was incorrect
                  </div>
                )}
                <form
                  method="post"
                  onSubmit={(event: SyntheticInputEvent<HTMLFormElement>) => {
                    const result = handleSubmit(event)

                    if (result) {
                      navigate(getUser().home, {
                        state: {
                          showBetaMessage: true,
                        },
                      })
                    } else {
                      setError(true)
                    }
                  }}
                >
                  <h2 className="sr-only">Login</h2>
                  <div className="form-group">
                    <label className="w-100" htmlFor="username">
                      <span className="small">Username</span>
                      <input
                        type="text"
                        className="form-control"
                        name="username"
                        id="username"
                        value={username}
                        required
                        onChange={(e: SyntheticInputEvent<HTMLInputElement>) => (
                          setUsername(e.target.value)
                        )}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="w-100" htmlFor="password">
                      <span className="small">Password</span>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        value={password}
                        required
                        onChange={(e: SyntheticInputEvent<HTMLInputElement>) => (
                          setPassword(e.target.value)
                        )}
                      />
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary btn-sm w-100">Log In</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Index
